import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import PropTypes from 'prop-types';
import React from 'react';

import Contact from '../components/framework/contact';

import Framework from '../components/framework';
import Seo from '../components/framework/seo';
import withLocation from '../middleware/withLocation';

const Kontakt = ({ location }) => {
	const { t } = useTranslation('contact');

	return (
		<Framework location={location}>
			<Seo
				description={t('meta_description')}
				tags={t('meta_keywords')}
				title={t('title')}
			/>

			<div className="container mt-3 pt-lg-5">
				<h1 className="h2">{t('t1')}</h1>
				<h2 className="title-small mt-3 my-mt-5">{t('t2')}</h2>
				<Contact />
				<h2 className="title-small mt-5 my-mt-5">{t('t3')}</h2>
				<p>{t('t4')}</p>
				<p className="mb-5 small">info@zample.nl</p>
			</div>
		</Framework>
	);
};

Kontakt.propTypes = {
	location: PropTypes.object.isRequired,
};

export default withLocation(Kontakt);

export const query = graphql`
	query {
		locales: allTranslation(filter: { id: { ne: "dummy" } }) {
			...translationFields
		}
	}
`;
